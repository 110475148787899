






















































































































































































































import { appProvider } from '@/app-providers'
import { IReactionDisposer, reaction } from 'mobx'
import { Observer } from 'mobx-vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { StakingServiceItemViewModel, stakingServiceViewModel } from '../viewmodels/staking-service-viewmodel'

@Observer
@Component({
  components: {
    StakingCard: () => import('../components/staking-card.vue'),
    UpdateAPRDialog: () => import('../dialogs/farm-service-update-apr-dialog.vue'),
    FarmServiceStakingDialog: () => import('../dialogs/farm-service-staking-dialog.vue'),
    FarmServiceApyConfigDialog: () => import('../dialogs/farm-service-apy-config-dialog.vue'),
    FarmServiceUpdateAprDialog: () => import('../dialogs/farm-service-update-apr-dialog.vue'),
    FarmServiceFundRewardDialog: () => import('../dialogs/farm-service-fund-reward-dialog.vue'),
  },
})
export default class StakingList extends Vue {
  providers = appProvider
  serviceVM = stakingServiceViewModel
  vm: StakingServiceItemViewModel | null = null
  _disposers: IReactionDisposer[] = []

  @Watch('$route.params.id', { immediate: true })
  async onPoolIdChanged(val: string) {
    if (val) {
      this.serviceVM.loadPool(val)
    }
  }

  mounted() {
    this._disposers = [
      reaction(
        () => this.serviceVM.selectedPool,
        () => {
          this.vm = this.serviceVM.selectedPool
        },
        { fireImmediately: true }
      ),
    ]
  }

  destroyed() {
    this._disposers.forEach((d) => d())
  }

  openUrl(url) {
    window.open(url, '_blank')
  }
}
